<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="div-footer">
    <div class="container">
      <div class="div row">
        <div class="col-md-7">
          <div class="research-publication">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f418ab8cba7867296c5b168b37211108f46d6711ef9766e5688bcb5a16dfb1a7?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
              alt="Research Publication Logo"
              class="logo"
            />
            <p class="description">
              {{ $t("message.footerDesc") }}
            </p>
            <p class="description" style="margin-top: 12px">
              {{ $t("message.footerDesc2") }}
            </p>
            <h1 class="title">{{ $t("message.footerSubDesc") }}</h1>
            <nav class="nav-container">
              <router-link to="/" class="nav-item nav-item-about">
                {{ $t("message.aboutSotor") }}</router-link
              >
              <router-link
                to="/researchers-services"
                class="nav-item nav-item-published"
              >
                {{ $t("message.resServices") }}</router-link
              >
              <router-link to="/contact-us" class="nav-item nav-item-contact">
                {{ $t("message.contact") }}</router-link
              >
            </nav>
          </div>
        </div>
        <div class="col-md-5">
          <div class="contact-section footer-contact">
            <h2 class="contact-title">{{ $t("message.contact") }}</h2>
            <p class="contact-email">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/8efe218745f440803022e2d04ed41e15fde62df68fb12ccf5957032750fd0d74?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                alt=""
                class="contact-icon"
              /><a
                href="mailto:Info@linesofknowledge.sa"
                class="contact-text foot-con"
                >Info@linesofknowledge.sa</a
              >
            </p>
            <p style="direction: ltr" class="contact-phone">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e399125bb2abdc72338b237539592a16dc9f17b09510ba2c1557a29a0de3a9ee?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                alt=""
                class="contact-icon"
              /><a
                style="direction: ltr"
                id="web-ph"
                href="tel:+966566432362"
                class="contact-text foot-con"
                >+966566432362</a
              >
            </p>
            <p class="footer-cr">
              <span> {{ $t("message.footerCR") }} : </span>
              <span>5906338331</span>
            </p>
            <p class="footer-address">
              <span> {{ $t("message.footerAddress") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script></script>

<style>
.div-footer {
  padding-top: 20px;
  padding-bottom: 40px;
  justify-content: flex-end;
  background-color: rgb(0, 9, 132);
  display: flex;
  flex-direction: column;
  color: #fff;
}
style > .div-footer {
  display: flex;
  max-width: 340px;
  flex-direction: column;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.div-2-footer {
  justify-content: flex-end;
  align-items: end;
  border-color: rgba(255, 255, 255, 1);
  border-style: dashed;
  border-bottom-width: 1px;
  width: 97%;
  padding: 0 0px 25px;
  font: 22px/90% Tajawal, sans-serif;
}
.div-3-footer {
  display: flex;
  margin-top: 18px;
  gap: 20px;
  font-size: 20px;
  text-align: right;
  line-height: 155%;
}
.div-4-foooter {
  align-self: start;
  display: flex;
  flex-direction: column;
  align-items: end;
  flex: 1;
}

.img-footer {
  aspect-ratio: 0.31;
  object-fit: auto;
  object-position: center;
  width: 6px;
}
.gap-70 {
  gap: 70px;
}
.links {
  border-bottom: 0.1px dotted #ddd;
  border-color: #ddd;
  border-style: dashed;
  border-width: 0;
  border-bottom-width: 0.1px;
  width: 97%;
  padding: 0 0px 25px;
  font: 22px/90% Tajawal, sans-serif;
  justify-content: flex-end;
  align-items: end;
  margin-bottom: 20px;
}
.div-footer li {
  padding-bottom: 10px;
}
.div-footer li a,
.div-footer li {
  padding-right: 5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
}
.div-footer .contact li {
  display: flex;
  gap: 5px;
}
.div-b-footer {
  justify-content: flex-end;
  align-items: center;
  background-color: #0c569c;
  display: flex;
  flex-direction: column;
  color: #fff;
  letter-spacing: -0.5px;
  padding: 50px 60px 24px;
}
@media (max-width: 991px) {
  .div-b-footer {
    padding: 0 20px;
  }
}
.div-2-b-footer {
  display: flex;
  margin-left: 109px;
  width: 892px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .div-2-b-footer {
    flex-wrap: wrap;
  }
}
.div-3-b-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (max-width: 991px) {
  .div-3-b-footer {
    max-width: 100%;
  }
}
.div-4-b-footer {
  leading-trim: both;
  text-edge: cap;
  align-self: center;
  font: 700 17px/101% Tajawal, sans-serif;
}
.div-5-b-footer {
  leading-trim: both;
  text-edge: cap;
  margin-top: 22px;
  font: 500 15px/114% Tajawal, sans-serif;
}
@media (max-width: 991px) {
  .div-5-b-footer {
    max-width: 100%;
  }
}
.div-6-b-footer {
  display: flex;
  gap: 17px;
  font-size: 15px;
  font-weight: 500;
  line-height: 114%;
  margin: auto 0;
}
.div-7-b-footer {
  leading-trim: both;
  text-edge: cap;
  font-family: Tajawal, sans-serif;
  flex-grow: 1;
}
.div-8-b-footer {
  leading-trim: both;
  text-edge: cap;
  font-family: Tajawal, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}
.footer-contact p {
  gap: 10px;
  display: flex;
  align-items: center;
}
.footer-contact p img {
  width: 34px;
}
.ar .footer-contact p {
  direction: ltr;
  text-align: right;
  justify-content: right;
  flex-direction: row-reverse;
  margin: 12px 0px;
}
.foot-con {
  color: #fff !important;
}
.ar .footer-cr span,
.ar .footer-address span {
  direction: rtl;
  font-family: Al-Jazeera-Arabic, sans-serif;
}
.footer-cr span:first-child {
  color: #eaa213;
}
.en .footer-address span {
  line-height: 30px;
}
</style>
